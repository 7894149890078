import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { PermissionService } from './auth/permission.service';
import { SigninService } from './auth/signin.service';
import { StorageService } from './storage.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthguardService implements CanActivate {
  constructor(
    private storageService: StorageService,
    private permissionService: PermissionService,
    private router: Router,
    private signinService: SigninService,
    private spinnerService: SpinnerService,
    private snackBar: MatSnackBar
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    let url =
      state.url.indexOf('/') !== -1
        ? state.url.substring(1, state.url.length)
        : state.url;

    if (      
      this.storageService.isAuthenticated() &&
      !this.permissionService.hasPermission('home')
      
    ) {
      this.signinService.doLogout();
      this.router.navigate(['/account/signin']);
    }
    else if(this.storageService.isChangePasswordActive())
    {
      this.router.navigate(['/account/signin']);
    }

    
   

    if (url.indexOf('list') !== -1 || url.indexOf('add') !== -1) {
      url = url.substring(0, url.indexOf('/'));
    }
    // console.log('-----------> ' + url);

    if (
      !this.storageService.isAuthenticated() ||
      !this.permissionService.hasPermission('ims:sales_login')
    ) {
      this.router.navigate(['/account/signin']);
      return of(false);
    } else if (this.permissionService.hasPermission(url)) {
      return of(true);
    } else {
      return of(true);
      // this.spinnerService.showMessage(this.snackBar, 'Access denied');
      // this.router.navigate(['/home']);
    }
  }
}
