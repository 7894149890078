<nz-card [nzTitle]="data.title" [nzExtra]="switchStyle">

  <div nz-row [nzGutter]="16" id="filter-container">
    <div nz-col nzFlex="auto">
      <nz-select style="width: 100%;" nzShowSearch [(ngModel)]="property" (ngModelChange)="showUploadIcon()"
        nzPlaceHolder="Select a property">
        <nz-option *ngFor="let property of properties" [nzLabel]="property.title" [nzValue]="property.id">
        </nz-option>
      </nz-select>
    </div>

    <div nz-col nzFlex="auto">
      <nz-select style="width: 100%;" nzShowSearch [(ngModel)]="type" (ngModelChange)="showUploadIcon()"
        nzPlaceHolder="Select type">
        <nz-option nzLabel="Issue" nzValue="1"></nz-option>
        <nz-option nzLabel="Supervision" nzValue="2"></nz-option>
        <nz-option nzLabel="Suspension" nzValue="3"></nz-option>
      </nz-select>
    </div>
  </div>

  <nz-divider></nz-divider>

  <nz-spin [nzSpinning]="isSpinning">
    <div style="height: 350px; overflow-y: scroll;">
      <nz-upload [nzAction]="nzAction" nzListType="picture" [(nzFileList)]="files" [nzDisabled]="!property || !type"
        [nzShowUploadList]="icons" [nzMultiple]="true" [nzBeforeUpload]="beforeUpload" [nzShowButton]="property && type"
        [nzPreview]="handlePreview" [nzRemove]="handleRemove" (nzChange)="stateChange($event)"
        [ngClass]="{'upload-list-inline': displayStyle === 'inline'}">

        <button nz-button style="padding: 0 8px;"><i nz-icon nzType="upload"></i>Upload</button>
      </nz-upload>

      <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null" nzClosable="false"
        (nzOnCancel)="previewVisible = false">
        <ng-template #modalContent>
          <img alt="order image preview" [src]="previewImage" [ngStyle]="{ width: '100%' }" />
        </ng-template>
      </nz-modal>
    </div>
  </nz-spin>

</nz-card>

<ng-template #switchStyle>
  <!-- <nz-switch [(ngModel)]="isInlineView" nzCheckedChildren="Inline" nzUnCheckedChildren="List"></nz-switch> -->
  <nz-radio-group [(ngModel)]="displayStyle">
    <label nz-radio-button nzValue="inline"><i nz-icon nzType="table"></i></label>
    <label nz-radio-button nzValue="list"><i nz-icon nzType="menu"></i></label>
  </nz-radio-group>
</ng-template>