<nz-card nzTitle="Select display columns" [nzExtra]="extraTemplate">

  <div *ngIf="isAnySelect" style="color: red; margin-bottom: 10px;">
    Please select the column to export
  </div>

  <div nz-row [nzGutter]="16">
    <div *ngFor="let column of columns" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="8" [nzLg]="6" [nzXl]="6" style="margin-bottom: 15px;">
      <label nz-checkbox [(ngModel)]="column.checked" (ngModelChange)="checkIsAllSelect()">{{column.label}}</label>
    </div>
  </div>

  <div style="text-align: right;">
    <button nz-button nzType="default" (click)="close()" style="margin-right: 20px;">Cancel</button>
    <button nz-button nzType="primary" (click)="doColumnSelection()">Ok</button>
  </div>

</nz-card>

<ng-template #extraTemplate>
  <label nz-checkbox [(ngModel)]="isAllSelect" (ngModelChange)="toggle()" [nzIndeterminate]="isIntermediate" style="margin-right: 10px;">Select all</label>
  <a nz-button nzType="link" (click)="reset()">Reset</a>
</ng-template>