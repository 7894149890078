import { SelectedRes } from './SelectedRes';
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { DateService } from '../date.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NzModalService } from 'ng-zorro-antd';
import { SalesWindowInfoViewComponent } from 'src/app/layouts/shared/modules/sales-window-info-view/sales-window-info-view.component';

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  lat = 28.419581;
  lng = 77.038085;
  zoom = 11;

  private readonly SELECTION = 'selected_building';
  public readonly FILTER_SELECTION = "filter_selection";
  public readonly FILTER_COORDINATES = "filter_coordinates";

  private filterStorage: any = {
    terminalStatus: [1, 0],
    entryStatus: ['PERMITTED'],
    isInside: [0, 1],
    showAll: false
  };
  private selectedRes: SelectedRes;


  public searchForm: any;
  // private listViewSelection: SelectionModel<any>;

  entryStatusType = [
    { entryStatus: 'PERMITTED', checked: true },
    { entryStatus: 'PROPERTY_PENDING', checked: false },
  ];

  terminalStatusType = [
    { terminalStatus: 'Online', checked: true, value: 1 },
    { terminalStatus: 'Offline', checked: true, value: 0 },
  ];

  onlineOfflineTime = [
    { label: 'Online in real time 60 sec.', value: 'on-60', checked: false },
    { label: 'Online in the last 24 hours', value: 'on-86400', checked: false },
    { label: 'Online in the last 72 hours', value: 'on-259200', checked: false },
    { label: 'Offline in real time 60 sec.', value: 'off-60', checked: false },
    { label: 'Offline in the last 24 hours', value: 'off-86400', checked: false },
    { label: 'Offline in the last 72 hours', value: 'off-259200', checked: false },
    // {label: 'Offline', value: 1, checked: false},
    { label: 'Not found', value: 'Not found', checked: false }
  ];

  public selectedFilterRegions: any = [];
  public selectedFilterPropertyImages: any = [1, 0];

  public circleOutsideSelected: any = [];
  public selectedProjectorSize: any = [];
  public selectedMagicPosterSize: any = [];
  public selectedScreenSize: any = [];
  public selectedStandingScreenSize: any = [];
  public selectedFramePosterSize: any = [];
  public selectedSize: any = [];
  public pBuildingCount = 0;
  public pPosterCount = 0;
  public pMagicPosterCount = 0;
  public pScreenCount = 0;
  public pStandingScreenCount = 0;
  public pDualScreenCount = 0;
  public pPopulationCount = 0;
  public pSelectedPopulation = 0;
  public pSelectedPopulationPrefix = '';

  public totalBuildings = 0;
  public totalLifts = 0;
  public totalTowers = 0;
  public totalStdPoster = 0;
  public totalScreen = 0;
  public totalStandingScreen = 0;
  public totalDualScreen = 0;
  public totalMagicPoster = 0;
  public totalPopulation = 0;
  public totalImpression = 0;
  public populationPostfix = '';
  public impressionPostfix = '';
  public isCircle = false;

  constructor(
    private http: HttpClient,
    private modalService: NzModalService,
    private dateService: DateService,
    private localStorage: LocalStorage
  ) { }

  setTotalInfo(buildings: any) {

    this.totalBuildings = 0;
    this.totalLifts = 0;
    this.totalTowers = 0;
    this.totalStdPoster = 0;
    this.totalScreen = 0;
    this.totalStandingScreen = 0;
    this.totalDualScreen = 0;
    this.totalMagicPoster = 0;
    this.totalPopulation = 0;
    this.totalImpression = 0;

    this.totalBuildings = buildings.length;

    buildings.forEach((building) => {
      if (building.liftNumber) {
        this.totalLifts = this.totalLifts + building.liftNo;
      }
      if (building.towerNumber) {
        this.totalTowers += building.towerNo
      }

      if (
        building.salesPoster === 0 &&
        building.salesMagicPoster === 0 &&
        building.salesScreen === 0 &&
        building.salesStandingScreen === 0 &&
        building.salesProjector === 0 &&
        building.salesDualScreen === 0
      ) {
        building.isDisable = true;
      } else {
        building.isDisable = false;
      }

      if (!this.filterStorage.showAll) {
        this.totalStdPoster += building.salesPoster;
        this.totalScreen += building.salesScreen;
        this.totalStandingScreen += building.salesStandingScreen;
        this.totalMagicPoster += building.salesMagicPoster;
        this.totalDualScreen += building.salesDualScreen
      } else {
        this.totalStdPoster += building.allPoster;
        this.totalScreen += building.allScreen;
        this.totalStandingScreen += building.allStandingScreen;
        this.totalMagicPoster += building.allMagicPoster;
        this.totalDualScreen += building.allDualScreen;
      }

      this.totalPopulation += building.population;

      if (building.buildingType.id === 1 || building.buildingType.id === 3) {
        this.totalImpression += building.population * 6;
      } else if (building.buildingType.id === 2) {
        this.totalImpression += building.population * 5;
      }
    });

    this.populationPostfix = '';
    if (this.totalPopulation >= 1000) {
      this.populationPostfix = ' K+';
      this.totalPopulation /= 1000;
      if (this.totalPopulation >= 1000) {
        this.populationPostfix = ' M+';
        this.totalPopulation /= 1000;
        if (this.totalPopulation >= 1000) {
          this.populationPostfix = ' B+';
          this.totalPopulation /= 1000;
        }
      }
    }

    this.impressionPostfix = '';
    if (this.totalImpression >= 1000) {
      this.impressionPostfix = ' K+';
      this.totalImpression /= 1000;
      if (this.totalImpression >= 1000) {
        this.impressionPostfix = ' M+';
        this.totalImpression /= 1000;
        if (this.totalImpression >= 1000) {
          this.totalImpression /= 1000;
          this.impressionPostfix = ' B+';
        }
      }
    }

    const selectedRes = this.getSelectedResFromMemory();
    if (selectedRes.orderDates) {
      this.totalImpression = parseFloat(this.totalImpression.toFixed(2));
      this.totalPopulation = parseFloat(this.totalPopulation.toFixed(2));
    } else {
      this.totalImpression = 9;
      this.totalPopulation = 3;
    }
  }

  setSearchForm(searchForm: any) {
    this.searchForm = searchForm;
  }

  // select all buildings, all points
  selectUnselectAll(allSelected: boolean, buildings: any) {
    if (!allSelected) {
      buildings.forEach((building) => {
        this.pBuildingCount -= 1;
        this.pPosterCount -= building.pPoster;
        this.pMagicPosterCount -= building.pMagicPoster;
        this.pScreenCount -= building.pScreen;
        this.pStandingScreenCount -= building.pStandingScreen;
        this.pDualScreenCount -= building.pDualScreen;
        this.pPopulationCount -= building.population;

        this.updateBuilding(false, building); // 

        const index = this.selectedRes.selected.findIndex(
          (selectedBuilding) => selectedBuilding.id === building.id
        );
        this.selectedRes.selected.splice(index, 1);
      });

      if (buildings.length === 0) {
        this.selectedRes.selected = [];
        this.pBuildingCount = 0;
        this.pPosterCount = 0;
        this.pMagicPosterCount = 0;
        this.pScreenCount = 0;
        this.pStandingScreenCount = 0;
        this.pDualScreenCount = 0;
        this.pPopulationCount = 0;
      }
    }

    if (allSelected) {
      buildings.forEach((building) => {
        if (
          !this.selectedRes.selected.some(
            (selectedBuilding) => selectedBuilding.id === building.id
          )
        ) {
          this.pPosterCount += building.salesPoster;
          this.pMagicPosterCount += building.salesMagicPoster;
          this.pScreenCount += building.salesScreen;
          this.pStandingScreenCount += building.salesStandingScreen;
          this.pDualScreenCount += building.salesDualScreen;
          this.pPopulationCount += building.population;

          this.selectedRes.selected.push(building);
          this.updateBuilding(true, building);
        }
      });
      this.pBuildingCount = this.selectedRes.selected.length;
    }

    this.setSelectedRes();
  }

  importOrderData(pointIds: any, buildings: any) {
    console.log("导入的订单数据有：" + pointIds.length + "条");

    buildings.forEach((building) => {

      let isBuildingIncluded = false;
      if (!this.selectedRes.selected.some((selectedBuilding) => selectedBuilding.id === building.id)) {


        const screenIds = new Array<string>();
        const standingScreenIds = new Array<string>();
        const dualScreenIds = new Array<string>();
        const posterIds = new Array<string>();
        const magicPosterIds = new Array<string>();

        building.salesScreenIds.forEach((screenID) => {
          if (pointIds.includes("" + screenID)) {
            screenIds.push(screenID);
            isBuildingIncluded = true;
          }
        });

        building.salesStandingScreenIds.forEach(screenId => {
          if (pointIds.includes("" + screenId)) {
            standingScreenIds.push(screenId);
            isBuildingIncluded = true;
          }
        });

        building.salesPosterIds.forEach(posterId => {
          if (pointIds.includes("" + posterId)) {
            posterIds.push(posterId);
            isBuildingIncluded = true;
          }
        });

        building.salesMagicPosterIds.forEach(magicPosterId => {
          if (pointIds.includes("" + magicPosterId)) {
            magicPosterIds.push(magicPosterId);
            isBuildingIncluded = true;
          }
        });

        building.salesDualScreenIds.forEach(dualScreenId => {
          if (pointIds.includes("" + dualScreenId)) {
            dualScreenIds.push(dualScreenId);
            isBuildingIncluded = true;
          }
        });

        if (isBuildingIncluded) {
          //楼宇和表格里的数据匹配
          this.pBuildingCount += 1;
          this.pPopulationCount += building.population;

          this.pScreenCount += screenIds.length;
          this.pStandingScreenCount += standingScreenIds.length;
          this.pPosterCount += posterIds.length;
          this.pMagicPosterCount += magicPosterIds.length;
          this.pDualScreenCount += dualScreenIds.length;

          building.pScreen = screenIds.length;
          building.pStandingScreen = standingScreenIds.length;
          building.pPoster = posterIds.length;
          building.pMagicPoster = magicPosterIds.length;
          building.pDualScreen = dualScreenIds.length;

          building.pScreenIds = screenIds.join(',');
          building.pStandingScreenIds = standingScreenIds.join(',');
          building.pPosterIds = posterIds.join(',');
          building.pMagicPosterIds = magicPosterIds.join(',');
          building.pDualScreenIds = dualScreenIds.join(',');

          if (screenIds.length === building.salesScreenIds.length && standingScreenIds.length === building.salesStandingScreenIds.length
            && posterIds.length === building.salesPosterIds.length && magicPosterIds.length === building.salesMagicPosterIds.length
            && dualScreenIds.length === building.salesDualScreenIds.length) {
            building.isChecked = true;
            building.isIndeterminate = false;
          } else {
            building.isChecked = false;
            building.isIndeterminate = true;
          }
          //清除除屏幕以外的数据
          this.selectedRes.selected.push(building);
        }
      }
    });
    this.setSelectedRes();
  }

  setSelectedPopulation() {
    this.pSelectedPopulation = this.pPopulationCount;

    this.pSelectedPopulationPrefix = '';
    if (this.pSelectedPopulation >= 1000) {
      this.pSelectedPopulationPrefix = ' K';
      this.pSelectedPopulation /= 1000;
      if (this.pSelectedPopulation >= 1000) {
        this.pSelectedPopulationPrefix = ' M';
        this.pSelectedPopulation /= 1000;
        if (this.pSelectedPopulation >= 1000) {
          this.pSelectedPopulationPrefix = ' B';
          this.pSelectedPopulation /= 1000;
        }
      }
    }
  }

  // select single building, all points
  selectUnselect(isSelected: boolean, building: any, isNewAdded = true) {
    // console.log("selectUnselect");
    if (isSelected) {
      if (isNewAdded) {
        // increase totoal no. of building/poster/magic poster/screen
        this.pBuildingCount = this.pBuildingCount + 1;

        this.pPosterCount += building.salesPoster;
        this.pMagicPosterCount += building.salesMagicPoster;
        this.pScreenCount += building.salesScreen;
        this.pStandingScreenCount += building.salesStandingScreen;
        this.pDualScreenCount += building.salesDualScreen;
        this.pPopulationCount += building.population;

        this.selectedRes.selected.push(building);
      } else {
        this.pPosterCount = 0;
        this.pMagicPosterCount = 0;
        this.pScreenCount = 0;
        this.pStandingScreenCount = 0;
        this.pDualScreenCount = 0;
        this.pPopulationCount = 0;

        this.selectedRes.selected.forEach((item) => {
          if (item.id === building.id) {
            item.pScreenIds = item.salesScreenIds.join(',');
            item.pScreen = item.salesScreen;
            item.pStandingScreenIds = item.salesStandingScreenIds.join(',');
            item.pStandingScreen = item.salesStandingScreen;
            item.pDualScreen = item.salesDualScreen;
            item.pDualScreenIds = item.salesDualScreenIds.join(',');
            item.pPosterIds = item.salesPosterIds.join(',');
            item.pPoster = item.salesPoster;
            item.pMagicPosterIds = item.salesMagicPosterIds.join(',');
            item.pMagicPoster = item.salesMagicPoster;
            item.pPopulation = item.population;
          }
          this.pScreenCount += item.pScreen;
          this.pStandingScreenCount += item.pStandingScreen;
          this.pDualScreenCount += item.pDualScreen;
          this.pPosterCount += item.pPoster;
          this.pMagicPosterCount += item.pMagicPoster;
          this.pPopulationCount += item.population;
        });
      }
    } else {
      // descrease totoal no. of building/poster/magic poster/screen
      this.pBuildingCount = this.pBuildingCount - 1;
      this.pPosterCount -= building.pPoster;
      this.pMagicPosterCount -= building.pMagicPoster;
      this.pScreenCount -= building.pScreen;
      this.pStandingScreenCount -= building.pStandingScreen;
      this.pDualScreenCount -= building.pDualScreen;
      this.pPopulationCount -= building.population;

      this.selectedRes.selected = this.selectedRes.selected.filter((item) => {
        return item.id !== building.id;
      });
    }

    this.setSelectedRes();
  }

  dateChange(orderDates: any) {
    this.selectedRes.orderDates = orderDates;
    this.selectUnselectAll(false, []);
  }

  // update single building, points count
  updatePoints(building: any) {

    console.log("updatePoints");
    this.pPosterCount = 0;
    this.pMagicPosterCount = 0;
    this.pScreenCount = 0;
    this.pStandingScreenCount = 0;
    this.pDualScreenCount = 0;
    this.pPopulationCount = 0;

    let isNewAdded = true;
    if (!this.selectedRes) {
      this.selectedRes = this.getSelectedResFromMemory();
    }
    this.selectedRes.selected.forEach((res) => {
      if (res.id === building.id) {
        isNewAdded = false;

        // if (
        //   building.pPoster === 0 &&
        //   building.pMagicPoster === 0 &&
        //   building.pScreen === 0 &&
        //   building.pStandingScreen === 0 && 
        //   building.pDualScreen === 0
        // ) {
        //   // unselected one
        //   this.pBuildingCount -= 1;
        //   this.selectedRes.selected = this.selectedRes.selected.filter(
        //     (item) => {
        //       return (
        //         item.pPoster + item.pMagicPoster + item.pScreen >
        //         0 + item.pStandingScreen
        //       );
        //     }
        //   );
        // }
        res.pScreen = building.pScreen;
        res.pStandingScreen = building.pStandingScreen;
        res.pDualScreen = building.pDualScreen;
        res.pPoster = building.pPoster;
        res.pMagicPoster = building.pMagicPoster;
        res.pPopulation = building.population;

        res.pScreenIds = building.pScreenIds;
        res.pStandingScreenIds = building.pStandingScreenIds;
        res.pDualScreenIds = building.pDualScreenIds;
        res.pPosterIds = building.pPosterIds;
        res.pMagicPosterIds = building.pMagicPosterIds;
      }
      this.pPosterCount += res.pPoster;
      this.pMagicPosterCount += res.pMagicPoster;
      this.pScreenCount += res.pScreen;
      this.pStandingScreenCount += res.pStandingScreen;
      this.pDualScreenCount += res.pDualScreen;
      this.pPopulationCount += res.population;
    });

    if (isNewAdded) {
      this.selectedRes.selected.push(building);
      // Add new selected      
      this.pBuildingCount += 1;
      this.pPosterCount += building.pPoster;
      this.pMagicPosterCount += building.pMagicPoster;
      this.pScreenCount += building.pScreen;
      this.pStandingScreenCount += building.pStandingScreen;
      this.pDualScreenCount += building.pDualScreen;
      this.pPopulationCount += building.population;
    }

    this.setSelectedRes();
  }

  getSelectedResFromMemory(): SelectedRes {
    return this.selectedRes;
  }

  // retrieve storage selected res
  recoverFromLocalStorage(): void {
    console.log("updatePoints");
    this.localStorage
      .getItem(this.SELECTION)
      .subscribe((selectedRes: SelectedRes) => {
        if (selectedRes) {
          this.selectedRes = selectedRes;
          this.pBuildingCount = 0;
          this.pPosterCount = 0;
          this.pMagicPosterCount = 0;
          this.pScreenCount = 0;
          this.pStandingScreenCount = 0;
          this.pDualScreenCount = 0;
          this.pPopulationCount = 0;

          selectedRes.selected.forEach((row) => {
            this.pBuildingCount++;
            this.pPosterCount += row.pPoster;
            this.pMagicPosterCount += row.pMagicPoster;
            this.pScreenCount += row.pScreen;
            this.pStandingScreenCount += row.pStandingScreen;
            this.pDualScreenCount += row.pDualScreen;
            this.pPopulationCount += row.population;
          });
        } else {
          this.selectedRes = new SelectedRes();
          console.warn('There is no storage data.');
        }
      });

    this.localStorage.getItem(this.FILTER_SELECTION).subscribe((filterStorage: any) => {
      if (filterStorage) {
        this.filterStorage = filterStorage;
      } else {
        this.filterStorage = {
          terminalStatus: [1, 0],
          entryStatus: ['PERMITTED'],
          isInside: [0, 1],
          showAll: false
        };
      }
    });
  }

  public setSelectedRes(): void {
    this.localStorage.removeItem(this.SELECTION).subscribe(() => {
      this.selectedRes.time = new Date().getTime();
      this.localStorage
        .setItem(this.SELECTION, this.selectedRes)
        .subscribe(() => { });
    });
    this.setSelectedPopulation();
  }

  private removeSelectedRes(): void {
    this.localStorage.removeItem(this.SELECTION).subscribe(() => { });
  }

  public isDateNotSelected() {
    const selectedRes = this.getSelectedResFromMemory();

    if (selectedRes.orderDates)
      return false;

    return true;
  }

  findSalesBuilding(coordinates: any) {

    const selectedRes = this.getSelectedResFromMemory();

    this.filterStorage.coordinates = coordinates;

    const data: any = Object.assign(this.filterStorage);
    if (selectedRes.orderDates) {
      data.startDate = this.dateService.convertDate(new Date(selectedRes.orderDates.begin));
      data.endDate = this.dateService.convertDate(new Date(selectedRes.orderDates.end));
    } else {
      data.startDate = undefined;
      data.endDate = undefined;
    }

    if (this.circleOutsideSelected.length) {
      data.circleOutsideSelected = this.circleOutsideSelected;
    }

    if (data.productTypeIds && data.productTypeIds.length === 0) {
      data.productTypeIds = null;
    }

    if (this.filterStorage.isInsideOutside && this.filterStorage.isInsideOutside.length === 1) {
      data.isInside = this.filterStorage.isInsideOutside[0];
    } else {
      data.isInside = null;
    }

    if (!this.filterStorage.pointOfLift || this.filterStorage.pointOfLift === '') {
      const selectedRes = this.getSelectedResFromMemory();
      this.filterStorage.pointOfLift = selectedRes.pointOfLift;
    }

    if (!this.filterStorage.videoTime || this.filterStorage.videoTime === '') {
      const selectedRes = this.getSelectedResFromMemory();
      this.filterStorage.videoTime = selectedRes.videoTime;
    }

    this.updateFilterStorage();

    return this.http.post(`${environment.API_URL}/sales`, data);
  }

  findBuildings() {
    return this.http.get(`${environment.API_URL}/sales/building`);
  }

  findAllTags() {
    return this.http.get(`${environment.API_URL}/property/tag/tags`);
  }

  getCompany() {
    return this.http.get(`${environment.API_URL}/company`);
  }

  findPointDetail(buildingId: number) {
    this.searchForm.patchValue({ buildingId });
    const params = this.createHttpParams();
    return this.http.get(`${environment.API_URL}/sales/point`, { params });
  }

  findPointDetailCart(buildingId: number) {
    const selectedRes = this.getSelectedResFromMemory();
    let params = new HttpParams();

    params = params.set(
      'startDate',
      this.dateService.convertDate(new Date(selectedRes.orderDates.begin))
    );
    params = params.set(
      'endDate',
      this.dateService.convertDate(new Date(selectedRes.orderDates.end))
    );
    params = params.set('buildingId', buildingId.toString());
    params = params.set('pointOfLift', this.filterStorage.pointOfLift);
    params = params.set('videoTime', this.filterStorage.videoTime);

    return this.http.get(`${environment.API_URL}/sales/point`, { params });
  }

  createHttpParams() {
    let params = new HttpParams();
    const selectedRes = this.getSelectedResFromMemory();

    if (selectedRes.orderDates) {
      params = params.set(
        'startDate',
        this.dateService.convertDate(new Date(selectedRes.orderDates.begin))
      );
      params = params.set(
        'endDate',
        this.dateService.convertDate(new Date(selectedRes.orderDates.end))
      );
    }

    params = params.set('showAll', this.filterStorage.showAll);

    if (this.filterStorage.cityIds) {
      params = params.set('cityIds', this.filterStorage.cityIds);
    }

    if(this.filterStorage.tagIds){
      params = params.set('tagIds', this.filterStorage.tagIds)
    }

    if (this.selectedFilterRegions.length > 0) {
      params = params.set('regionIds', this.selectedFilterRegions);
    }
    if (this.filterStorage.propertyTypeIds) {
      params = params.set('propertyTypeIds', this.filterStorage.propertyTypeIds);
    }

    if (this.filterStorage.entryStatus) {
      params = params.set('entryStatus', this.filterStorage.entryStatus);
    }

    if (this.filterStorage.stateIds) {
      params = params.set('stateIds', this.filterStorage.stateIds);
    }

    if (this.filterStorage.floorIds) {
      params = params.set('floorIds', this.filterStorage.floorIds);
    }

    if (this.filterStorage.productTypeIds) {
      params = params.set('productTypeIds', this.filterStorage.productTypeIds);
    }

    params = params.set(
      'pointOfLift',
      this.filterStorage.pointOfLift
    );
    params = params.set('videoTime', this.filterStorage.videoTime);

    if (this.searchForm.get('coveredPopulation').value !== '') {
      params = params.set(
        'population',
        this.searchForm.get('coveredPopulation').value
      );
    }

    if (this.searchForm.get('occupancyRate').value !== '') {
      params = params.set(
        'occupancyRate',
        this.searchForm.get('occupancyRate').value
      );
    }
    if (this.searchForm.get('buildingId').value) {
      params = params.set(
        'buildingId',
        this.searchForm.get('buildingId').value
      );
    }

    if (this.filterStorage.size) {
      params = params.set('size', this.filterStorage.size);
    }

    if (this.filterStorage.pincode && this.filterStorage.pincode.length === 6) {
      params = params.set('pincode', this.filterStorage.pincode);
    }
    return params;
  }

  setMarkerData(data: any) {
    const icons: any = [
      'assets/images/icon/C.png',
      'assets/images/icon/R.png',
      'assets/images/icon/H.png',
      'assets/images/icon/O.png',
    ];

    const selectBuildingIcons: any = [
      'assets/images/icon/MC.png',
      'assets/images/icon/MR.png',
      'assets/images/icon/MH.png',
      'assets/images/icon/MO.png',
    ];

    for (const building of data) {
      building.resourceStatus = building.entryStatus.replace('_', ' ');

      let icon = '';
      if (this.circleOutsideSelected.some((id) => id === building.id)) {
        icon = selectBuildingIcons[building.buildingType.id - 1];
      } else {
        icon = icons[building.buildingType.id - 1];
      }

      const images = [];

      if (building.image && building.image.images !== '') {
        building.image.images.split(',').forEach((imageTemp) => {
          if (!imageTemp.startsWith('http')) {
            images.push(
              'https://res-bbmedia-in.s3.ap-south-1.amazonaws.com/' + imageTemp
            );
          } else {
            images.push(imageTemp);
          }
        });
      } else {
        images.push('assets/images/screen.jpg');
        images.push('assets/images/poster.jpg');
        icon = icon.replace('.png', 'O.png');
      }

      const coordinates = building.coordinates.split(',');
      building.lat = coordinates[0];
      building.lng = coordinates[1];
      building.icon = icon;
      building.imagesArray = images;
      building.isSelected = false;
      building.pPoster = 0;
      building.pScreen = 0;
      building.pStandingScreen = 0;
      building.pMagicPoster = 0;
      building.pDualScreen = 0;
    }
  }

  setDefaultCheck(filterBuildings: any) {
    if (filterBuildings) {
      filterBuildings.forEach((building) => {
        building.checked = false;
      });
    }
  }

  setDefaultCheckTag(filteredTags: any) {
    if (filteredTags) {
      filteredTags.forEach(tag => {
        tag.checked = false;
      });
    }
  }

  setDefaultSizeCheck(productSize: any) {
    if (productSize) {
      productSize.forEach((product) => {
        product.checked = false;
      });
    }
  }

  clearSelectedRes() {
    this.selectedRes = new SelectedRes();
  }

  updateBuilding(isSelected: boolean, building: any) {
    if (isSelected) {

      building.pPoster = building.salesPoster;
      building.pMagicPoster = building.salesMagicPoster;
      building.pScreen = building.salesScreen;
      building.pStandingScreen = building.salesStandingScreen;
      building.pDualScreen = building.salesDualScreen;

      building.pPosterIds = building.salesPosterIds.join(',');
      building.pMagicPosterIds = building.salesMagicPosterIds.join(',');
      building.pScreenIds = building.salesScreenIds.join(',');
      building.pStandingScreenIds = building.salesStandingScreenIds.join(',');
      building.pDualScreenIds = building.salesDualScreenIds.join(',');
    } else {
      building.pPoster = 0;
      building.pMagicPoster = 0;
      building.pScreen = 0;
      building.pStandingScreen = 0;
      building.pDualScreen = 0;

      building.pPosterIds = '';
      building.pMagicPosterIds = '';
      building.pScreenIds = '';
      building.pStandingScreenIds = '';
      building.pDualScreenIds = '';
    }
  }

  findAvailableTower(points: string) {
    const params = new HttpParams().set('points', points);
    return this.http.get(`${environment.API_URL}/sales/towers`, { params });
  }

  setPopulation() {
    this.pPopulationCount = 0;
    this.selectedRes.selected.forEach((building) => {
      this.pPopulationCount += building.population;
    });
  }

  coordinateMultiple(data: any) {
    let params = new HttpParams();
    const selectedRes = this.getSelectedResFromMemory();

    params = params.set(
      'startDate',
      this.dateService.convertDate(new Date(selectedRes.orderDates.begin))
    );
    params = params.set(
      'endDate',
      this.dateService.convertDate(new Date(selectedRes.orderDates.end))
    );
    params = params.set('showAll', this.filterStorage.showAll);
    params = params.set('pointOfLift', this.filterStorage.pointOfLift);
    params = params.set('videoTime', this.filterStorage.videoTime);
    params = params.set('coordinates', data.coordinates);
    params = params.set('radius', data.radius);

    return this.http.get(`${environment.API_URL}/sales/coordinates`, {
      params,
    });
  }

  toggleMap(building: any) {
    if (this.circleOutsideSelected.some((id) => id === building.id)) {
      this.circleOutsideSelected = this.circleOutsideSelected.filter(
        (id) => id !== building.id
      );
    } else {
      this.circleOutsideSelected.push(building.id);
    }
  }

  openSalesWindowInfo(property: any) {

    const modal = this.modalService.create({
      nzTitle: '',
      nzContent: SalesWindowInfoViewComponent,
      nzComponentParams: {
        property,
      } as any,
      nzFooter: null,
      nzClosable: false,
      nzBodyStyle: {
        padding: '0',
      },
      nzWidth: '80vw',
      nzStyle: { 'min-width': '720px' },
    });

    return modal;
  }

  findProductSize() {
    return this.http.get(`${environment.API_URL}/sales/product/size`);
  }

  getFilterStorage() {

    return this.filterStorage;
  }

  updateFilterStorage() {

    this.localStorage.setItem(this.FILTER_SELECTION, this.filterStorage).subscribe(() => { });
  }

}