import { Component, OnInit, Input, Injectable } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-table-column-selection',
  templateUrl: './table-column-selection.component.html',
  styleUrls: ['./table-column-selection.component.css']
})
export class TableColumnSelectionComponent implements OnInit {

  isAllSelect = false;
  isIntermediate = false;
  isAnySelect =  false;
  isColumnSelect = false;
  selectedColumns: any;

  @Input() columns?: any;
  @Input() original?: any;
  @Input() key?: any;
  previousColumns: any;

  constructor(private modalRef: NzModalRef,
              private localStorage: LocalStorage) {
      this.columns = this.columns;
      this.original = this.original;
      this.key = this.key;
     }

  ngOnInit() {

    this.previousColumns = [...this.columns].map((item) => ({ ...item }));
    this.checkIsAllSelect();
  }

  checkIsAllSelect() {
    const selectedCount = this.columns.filter(column => column.checked).length;

    if (selectedCount === this.columns.length) {
      this.isAllSelect = true;
      this.isIntermediate = false;
      this.isAnySelect = false;
    } else if (selectedCount > 0) {
      this.isAllSelect = false;
      this.isIntermediate = true;
      this.isAnySelect = false;
    } else {
      this.isAllSelect = false;
      this.isIntermediate = false;
      this.isAnySelect = true;
    }

  }

  toggle() {

    this.columns.forEach(column => column.checked = this.isAllSelect);
    this.checkIsAllSelect();
  }

  reset() {

    for (const column of this.columns) {
      for (const ori of this.original) {
        if (ori.key === column.key) {
          column.checked = ori.checked;
        }
      }
    }
    this.checkIsAllSelect();
  }

  close() {

    for (const column of this.columns) {
      for (const previous of this.previousColumns) {
        if (previous.key === column.key) {
          column.checked = previous.checked;
        }
      }
    }
    this.modalRef.destroy();
  }

  doColumnSelection() {
    const selectedCount = this.columns.filter(column => column.checked).length;
    if (selectedCount > 0) {
      this.localStorage.setItem(this.key, this.columns).subscribe(() => {});
    } else {
      for (const column of this.columns) {
        for (const previous of this.previousColumns) {
          if (previous.key === column.key) {
            column.checked = previous.checked;
          }
        }
      }

      this.localStorage.setItem(this.key, this.columns).subscribe(() => {});
      this.columns = this.columns.filter((column) => column.checked);
    }

    if (this.columns.filter(column => column.checked).length === 0) {
      return;
    }
    this.isColumnSelect = true;
    this.modalRef.destroy();
  }

}
