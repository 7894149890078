import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private modalService: NzModalService, private http: HttpClient) {}

  openUpload(data: any, content: any) {
    const modal = this.modalService.create({
      nzTitle: '',
      nzContent: content,
      nzComponentParams: {
        data,
      } as any,
      nzFooter: null,
      nzClosable: false,
      nzBodyStyle: {
        padding: '0',
      },
      nzWidth: '60vw',
      nzStyle: { 'min-width': '520px' },
    });

    return modal;
  }

  findAllImageByProperty(orderId: number, propertyId: number, type: number) {
    const params = new HttpParams()
      .set('orderId', orderId.toString())
      .set('propertyId', propertyId.toString())
      .set('type', type.toString());
    return this.http.get(
      `${environment.API_URL}/advertisement/image/property`,
      { params }
    );
  }

  deleteOrderImage(file: any) {
    const params = new HttpParams().set('id', file.id).set('url', file.url);
    return this.http.delete(`${environment.API_URL}/file/order/image`, {
      params,
    });
  }

  rotateOrderImage(file: any) {
    return this.http.put(`${environment.API_URL}/file/order/image`, file);
  }
}
