import { HomeService } from './layouts/authorized/home/home.service';
import { SalesService } from 'src/app/services/sales/sales.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'abbieit-client';
  navEnd: Observable<NavigationEnd>;

  constructor(private salesService: SalesService,
              private homeService: HomeService,             
              private router: Router) {

    this.navEnd = router.events.pipe(
      filter(evt => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  ngOnInit(): void {
    this.salesService.recoverFromLocalStorage();
    this.navEnd.subscribe(evt => this.homeService.showSidenavToggle = ('/home' === evt.url) || ('/' === evt.url));
  }
}
