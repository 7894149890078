<div nz-row>

  <div nz-col nzFlex="1">

    <nz-tabset class="window-tab" [(nzSelectedIndex)]="tabIndex"
      (nzSelectedIndexChange)="onSelectedIndexChange($event)">

      <nz-tab nzTitle="Screen">
      </nz-tab>

      <nz-tab nzTitle="Standing Screen">
      </nz-tab>

      <nz-tab nzTitle="Dual Screen">
      </nz-tab>

      <nz-tab nzTitle="Frame Poster">
      </nz-tab>

    </nz-tabset>

    <nz-table class="window-table" #basicTable [nzData]="tableData" [nzLoading]="isPointLoading">
      <thead>
        <tr>
          <th id='selectAll' nzShowCheckbox [nzDisabled]="!isPropertyAvailable || salesService.isDateNotSelected()"
            [nzIndeterminate]="selection.hasValue() && !isAllSelected()"
            [nzChecked]="selection.hasValue() && isAllSelected()" (nzCheckedChange)="allToggle()"></th>
          <th id='location'>Location</th>
          <th id='pointid'>Point ID</th>
          <th id='type'>Product Type</th>
          <th id='size'>Product Size</th>
          <th id='remark'>Remark</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let point of basicTable.data">
          <td nzShowCheckbox
            [nzDisabled]="!isPropertyAvailable || isPointAvailable(point.id) || salesService.isDateNotSelected()"
            [nzChecked]="selection.isSelected(point)" (nzCheckedChange)="singleSelectDeselect($event, point)"></td>
          <td>{{point | pointlocation}}</td>
          <td>{{point.id}}</td>
          <td>{{point.productType.type}}</td>
          <td>{{point.screenSize}}</td>
          <td>{{ (point.remark != null) ? (point.remark | slice:0:15) + (point.remark.length > 15 ? '...' :'') : '-' }}
          </td>
        </tr>
      </tbody>
    </nz-table>

  </div>

  <nz-divider nzType="vertical" nz-col nzFlex="0 1 1px" style="margin: 0; height: auto; align-self: stretch;">
  </nz-divider>

  <div nz-col style="display: flex; flex-direction: column; width: 40%;max-width: 480px;">

    <div style="text-align:center; padding: 30px 24px;">
      <h3>{{property.buildingName}}</h3>
      {{property.address}}<br>
      <span *ngIf="property.entryStatus !== 'PERMITTED'" style="color: red;">
        ({{ property.entryStatus.split("_").join(" ")}})
      </span>
    </div>

    <div id="images" style="height: 300px; width: 100%;">
      <nz-carousel nzAutoPlay>
        <div nz-carousel-content *ngFor="let image of property.imagesArray">
          <img [src]="image" [alt]="property.buildingName" style="height: 300px; width: 100%; cursor: pointer;">
        </div>
      </nz-carousel>
    </div>

    <div style="background-color: #000; color: #fff; text-align: center;">
      {{property.buildingType.type}} , Tower - {{property.towerNumber}}, Lift - {{property.liftNumber}}
    </div>

    <div
      style="padding: 20px; min-height: 160px; display: flex; flex-direction: row; flex-grow: 1; align-items: center; justify-content: center">


      <div style="text-align: center; padding: 0 10px; flex-grow: 1; display: flex; flex-direction: column">
        <div class="one-rem"
          style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center; margin-bottom: 4px">
          Screen
        </div>
        <span style="font-size: 16px; display: block; color: rgb(153, 153, 153)">
          {{screenSelection.selected.length}} / {{property.salesScreen}}
        </span>
      </div>

      <div style="width: 1px; height: 24px; background-color: #dfdfdf; margin: auto 0"></div>

      <div style="text-align: center; padding: 0 10px; flex-grow: 1">
        <span class="one-rem" style="margin-bottom: 4px">Std. Screen</span>
        <span style="font-size: 16px; display: block; color: rgb(153, 153, 153)">
          {{standingScreenSelection.selected.length}} / {{property.salesStandingScreen}}
        </span>
      </div>
      <div style="width: 1px; height: 24px; background-color: #dfdfdf; margin: auto 0"></div>

      <div style="text-align: center; padding: 0 10px; flex-grow: 1">
        <span class="one-rem" style="margin-bottom: 4px">Dual Screen</span>
        <span style="font-size: 16px; display: block; color: rgb(153, 153, 153)">
          {{dualScreenSelection.selected.length}} / {{property.salesDualScreen}}
        </span>
      </div>

      <div style="width: 1px; height: 24px; background-color:#dfdfdf; margin: auto 0"></div>

      <div style="text-align: center; padding: 0 10px; flex-grow: 1">
        <span class="one-rem" style="margin-bottom: 4px">Frame Poster</span>
        <span style="font-size: 16px; display: block; color: rgb(153, 153, 153)">
          {{posterSelection.selected.length}} / {{property.salesPoster}}
        </span>
      </div>

      <div style="width: 1px; height: 24px; background-color:#dfdfdf; margin: auto 0"></div>


    </div>

    <div>
      <button (click)="selectUnselect()"
        [disabled]="!isPropertyAvailable || property.isDisable || salesService.isDateNotSelected()" nz-button
        nzType="primary" style="width: 100%; border-radius: 0; height: 48px; border-bottom-right-radius: 2px;">
        {{isSelected ? 'DESELECT' : 'SELECT'}}
      </button>
    </div>

  </div>

</div>