import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthguardService } from './services/authguard.service';
import { LoginGuardService } from './services/login-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layouts/authorized/authorized.module').then(mod => mod.AuthorizedModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'account/signin',
    loadChildren: () => import('./layouts/unauthorized/signin/signin.module').then(mod => mod.SigninModule),
    canActivate: [LoginGuardService]
  },
  {
    path: 'building/image_view/:buildingId',
    loadChildren: () => import('./layouts/unauthorized/property-image-view/property-image-view.module').then(mod => mod.PropertyImageViewModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }