import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'app-selection-export',
  templateUrl: './selection-export.component.html',
  styleUrls: ['./selection-export.component.css']
})
export class SelectionExportComponent implements OnInit {

  allSelect = true;
  indeterminate = false;
  isAnySelect = false;
  doExport = false;
  groupBy: any = 'city';

  @Input() headers?: any;
  @Input() fileName?: any;

  constructor(private modalRef: NzModalRef) { }

  ngOnInit() {

    this.allSelect = this.headers.filter(header => header.checked).length === this.headers.length;
    if (this.allSelect) {
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
  }

  toggle() {

    this.headers.forEach(header => header.checked = this.allSelect);
    if (this.allSelect) {
      this.indeterminate = false;
    }
    this.checkIsAnySelect();
  }

  selectUnselect() {

    const currentCount = this.headers.length;
    const selectedCount = this.headers.filter(header => header.checked).length;

    if (currentCount === selectedCount) {
      this.indeterminate = false;
      this.allSelect = true;
    } else if (selectedCount > 0) {
      this.indeterminate = true;
    } else {
      this.indeterminate = false;
      this.allSelect = false;
    }
    this.checkIsAnySelect();
  }

  checkIsAnySelect() {

    this.isAnySelect = this.headers.filter(header => header.checked).length === 0;
  }

  export() {

    const selectedColumns = this.headers.filter(header => header.checked);
    if (selectedColumns.length === 0) {
      return;
    }

    this.doExport = true;
    this.modalRef.destroy();
  }

  close() {

    this.modalRef.destroy();
  }

}