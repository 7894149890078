import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pointlocation'
})
export class PointlocationPipe implements PipeTransform {

  transform(point: any, value?: any): any {
    let location = '';
    if (point.tower) {
      location = point.tower.name + ',';
    }
    if (point.floor) {
      location +=  point.floor.floorName + ',';
    }
    if (point.lift) {
      location +=  point.lift + ',';
    }
    if (point.position) {
      location +=  point.position.code;
    }
    return location;
  }


}
