import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainPipeComponent } from './main-pipe.component';
import { PointlocationPipe } from 'src/app/pipe/pointlocation.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MainPipeComponent, PointlocationPipe],
  exports: [PointlocationPipe]
})
export class MainPipeModule { }
