import { TokenService } from './services/auth/token.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StorageModule } from '@ngx-pwa/local-storage';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { FormsModule } from '@angular/forms';
import { DatePipe, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './strategy/CacheRouteReuseStrategy';
import { FileUploadModule } from './layouts/shared/modules/file-upload/file-upload.module';
import { SelectionExportModule } from './layouts/shared/modules/selection-export/selection-export.module';
import { TableColumnSelectionModule } from './layouts/shared/modules/table-column-selection/table-column-selection.module';
import { SalesWindowInfoViewModule } from './layouts/shared/modules/sales-window-info-view/sales-window-info-view.module';

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAf0aX6Jgz6HbqvFIhI9BejpKdx3tVhjTk&libraries=places',
    }),
    StorageModule.forRoot({
      IDBNoWrap: true,
    }),
    DeviceDetectorModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    MatSnackBarModule,
    NzModalModule,
    NzMessageModule,
    FormsModule,
    FileUploadModule,
    SelectionExportModule,
    TableColumnSelectionModule,
    SalesWindowInfoViewModule
  ],
  providers: [
    { provide: DatePipe },
    { provide: HTTP_INTERCEPTORS, useClass: TokenService, multi: true },
    { provide: NZ_I18N, useValue: en_US },
    { provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }