import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SalesService } from 'src/app/services/sales/sales.service';
import { SelectionModel } from '@angular/cdk/collections';
import Viewer from 'viewerjs';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';

@Component({
  selector: 'app-sales-window-info-view',
  templateUrl: './sales-window-info-view.component.html',
  styleUrls: ['./sales-window-info-view.component.css'],
})
export class SalesWindowInfoViewComponent implements OnInit {
  @Input() property?: any;
  isPropertyAvailable = true;
  @ViewChild(NzCarouselComponent) carousel: NzCarouselComponent;
  viewer: any;

  tabIndex = 0;

  isPointLoading = false;
  tableData: any;
  selection = new SelectionModel<any>(true, []);

  isSelected = false;

  screens: any;
  screenSelection = new SelectionModel<any>(true, []);

  standingScreens: any = [];
  standingScreenSelection = new SelectionModel<any>(true, []);

  posters: any;
  posterSelection = new SelectionModel<any>(true, []);

  dualScreens: any;
  dualScreenSelection = new SelectionModel<any>(true, []);

  magicPosters: any;
  magicSelection = new SelectionModel<any>(true, []);

  salesUsedPointIds: any;

  constructor(public salesService: SalesService) { }

  ngOnInit() {

    this.isPropertyAvailable =
      this.property.entryStatus ===
      this.salesService.entryStatusType[0].entryStatus;

    const selectedRes = this.salesService.getSelectedResFromMemory();
    const filtered = selectedRes.selected.filter((element, index, array) => {
      return element.id === this.property.id;
    });

    this.isSelected = filtered.length > 0;

    this.findPointDetail();
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.carousel.carouselContents.setDirty();
    // }, 200);
    this.buildViewer();
  }

  buildViewer() {
    this.ngOnDestroy();

    const overlayClassName = 'cdk-overlay-pane';
    const elementsArr = document.getElementsByClassName(
      'cdk-overlay-container'
    );
    let container;
    for (const element of Array.from(elementsArr)) {
      if (element.querySelector('app-sales-window-info-view')) {
        container = element;
        break;
      }
    }

    this.viewer = new Viewer(this.carousel.el, {
      container: container ? container : '',
      className: overlayClassName,
    });

    const viewerRootContainer = document.getElementsByClassName(
      'viewer-container'
    );
    this.carousel.el.addEventListener('show', () => {
      for (const ele of Array.from(viewerRootContainer)) {
        if (!ele.classList.contains(overlayClassName)) {
          ele.classList.add(overlayClassName);
        }
      }
    });

    this.carousel.el.addEventListener('hidden', () => {
      for (const ele of Array.from(viewerRootContainer)) {
        if (ele.classList.contains(overlayClassName)) {
          ele.classList.remove(overlayClassName);
        }
      }
    });
  }

  isAllSelected() {
    const allPoint = this.tableData.filter(
      (point) => this.salesUsedPointIds.indexOf(',' + point.id + ',') === -1
    );
    return this.selection.selected.length === allPoint.length;
  }

  allToggle() {

    let doUpdate = false;
    const action =
      this.tabIndex === 0
        ? 'pScreenIds'
        : this.tabIndex === 1
          ? 'pStandingScreenIds'
          : this.tabIndex === 2
            ? 'pDualScreenIds'
            : this.tabIndex === 3
              ? 'pPosterIds'
              : '';
    this.property[action] = '';

    if (this.isSelected && this.isAllSelected()) {
      doUpdate = true;
      this.selection.clear();
    } else {
      this.selection.clear();
      const ids = [];
      this.tableData.forEach((point) => {
        if (this.salesUsedPointIds.indexOf(',' + point.id + ',') === -1) {
          this.selection.select(point);
          ids.push(point.id);
          doUpdate = true;
        }
      });
      if (doUpdate) {
        this.property[action] = ids.join(',');
        if (!this.isSelected) {
          this.isSelected = true;
        }
      }
    }

    if (doUpdate) {
      this.updatePoints();
      this.setPopulation();
    }
  }

  selectUnselect() {

    this.salesService.pPosterCount -= this.property.pPoster;
    this.salesService.pMagicPosterCount -= this.property.pMagicPoster;
    this.salesService.pScreenCount -= this.property.pScreen;
    this.salesService.pStandingScreenCount -= this.property.pStandingScreen;
    this.salesService.pDualScreenCount -= this.property.pDualScreen;

    this.property.pScreen = 0;
    this.property.pStandingScreen = 0;
    this.property.pDualScreen = 0;
    this.property.pPoster = 0;
    this.property.pMagicPoster = 0;

    this.property.pScreenIds = '';
    this.property.pStandingScreenIds = '';
    this.property.pPosterIds = '';
    this.property.pMagicPosterIds = '';
    this.property.pDualScreenIds = '';

    let doUpdate = false;

    if (this.isSelected) {
      this.screenSelection.clear();
      this.standingScreenSelection.clear();
      this.posterSelection.clear();
      this.magicSelection.clear();
      this.dualScreenSelection.clear();
      doUpdate = true;
    } else {
      this.screenSelection.clear();
      this.screens.forEach((row) => {
        if (this.salesUsedPointIds.indexOf(',' + row.id + ',') === -1) {
          this.screenSelection.select(row);
          doUpdate = true;
        }
      });
      this.property.pScreen = this.property.salesScreen;
      this.property.pScreenIds = this.property.salesScreenIds.join(',');

      this.standingScreenSelection.clear();
      this.standingScreens.forEach((row) => {
        if (this.salesUsedPointIds.indexOf(',' + row.id + ',') === -1) {
          this.standingScreenSelection.select(row);
          doUpdate = true;
        }
      });
      this.property.pStandingScreen = this.property.salesStandingScreen;
      this.property.pStandingScreenIds = this.property.salesStandingScreenIds.join(
        ','
      );

      this.dualScreenSelection.clear();
      this.dualScreens.forEach((row) => {
        if (this.salesUsedPointIds.indexOf(',' + row.id + ',') === -1) {
          this.dualScreenSelection.select(row);
          doUpdate = true;
        }
      });
      this.property.pDualScreen = this.property.salesDualScreen;
      this.property.pDualScreenIds = this.property.salesDualScreenIds.join(
        ','
      );

      this.posterSelection.clear();
      this.posters.forEach((row) => {
        if (this.salesUsedPointIds.indexOf(',' + row.id + ',') === -1) {
          this.posterSelection.select(row);
          doUpdate = true;
        }
      });
      this.property.pPoster = this.property.salesPoster;
      this.property.pPosterIds = this.property.salesPosterIds.join(',');

      this.magicSelection.clear();
      this.magicPosters.forEach((row) => {
        if (this.salesUsedPointIds.indexOf(',' + row.id + ',') === -1) {
          this.magicSelection.select(row);
          doUpdate = true;
        }
      });
      this.property.pMagicPoster = this.property.salesMagicPoster;
      this.property.pMagicPosterIds = this.property.salesMagicPosterIds.join(
        ','
      );
    }

    if (doUpdate) {
      this.isSelected = !this.isSelected;
      this.salesService.selectUnselect(this.isSelected, this.property);
    }
  }

  findPointDetail() {
    this.isPointLoading = true;
    this.salesService
      .findPointDetail(this.property.id)
      .subscribe((data: any) => {
        this.screens = data.screens;
        this.posters = data.posters;
        this.magicPosters = data.magicPosters;
        this.standingScreens = data.standingScreens;
        this.dualScreens = data.dualScreens;

        this.salesUsedPointIds = data.salesUsedPointIds;
        this.setDefaultSelection();

        this.isPointLoading = false;
        this.onSelectedIndexChange(this.tabIndex);
      });
  }

  setDefaultSelection() {

    if (this.isSelected) {
      if (this.property.pScreenIds) {
        const screenIds = this.property.pScreenIds.split(',');
        this.screens.forEach((screen) => {
          screenIds.forEach((screenId) => {
            if (screen.id === +screenId) {
              this.screenSelection.select(screen);
            }
          });
        });
      }

      if (this.property.pStandingScreenIds) {
        const standingScreenIds = this.property.pStandingScreenIds.split(',');
        this.standingScreens.forEach((screen) => {
          standingScreenIds.forEach((screenId) => {
            if (screen.id === +screenId) {
              this.standingScreenSelection.select(screen);
            }
          });
        });
      }

      if (this.property.pDualScreenIds) {
        const dualScreenIds = this.property.pDualScreenIds.split(',');
        this.dualScreens.forEach((screen) => {
          dualScreenIds.forEach((screenId) => {
            if (screen.id === +screenId) {
              this.dualScreenSelection.select(screen);
            }
          });
        });
      }

      if (this.property.pPosterIds) {
        const posterIds = this.property.pPosterIds.split(',');
        this.posters.forEach((poster) => {
          posterIds.forEach((posterId) => {
            if (poster.id === +posterId) {
              this.posterSelection.select(poster);
            }
          });
        });
      }

      if (this.property.pMagicPosterIds) {
        const magicPosterIds = this.property.pMagicPosterIds.split(',');
        this.magicPosters.forEach((magicPoster) => {
          magicPosterIds.forEach((magicPosterId) => {
            if (+magicPoster.id === +magicPosterId) {
              this.magicSelection.select(magicPoster);
            }
          });
        });
      }
    }
  }

  onSelectedIndexChange(index: any) {
    this.tabIndex = index;
    if (this.tabIndex === 0) {
      this.tableData = this.screens;
      this.selection = this.screenSelection;
    } else if (this.tabIndex === 1) {
      this.tableData = this.standingScreens;
      this.selection = this.standingScreenSelection;
    } else if (this.tabIndex === 2) {
      this.tableData = this.dualScreens;
      this.selection = this.dualScreenSelection;
    } else if (this.tabIndex === 3) {
      this.tableData = this.posters;
      this.selection = this.posterSelection;
    }
  }

  screenSelectDeselect(screen: any) {
    if (this.screenSelection.isSelected(screen)) {
      this.property.pScreenIds = this.property.pScreenIds
        .split(',')
        .filter((id) => {
          return +id !== screen.id;
        })
        .join(',');
    } else {
      if (this.property.pScreenIds) {
        const ids = this.property.pScreenIds.split(',');
        ids.push(screen.id);
        this.property.pScreenIds = ids.join(',');
      } else {
        this.property.pScreenIds = screen.id + '';
      }

      if (!this.isSelected) {
        // from 0 to n
        this.isSelected = true;
      }
    }

    this.selection.toggle(screen);
    this.screenSelection = this.selection;
  }

  standingScreenSelectDeselect(standingScreen: any) {

    if (this.standingScreenSelection.isSelected(standingScreen)) {
      this.property.pStandingScreenIds = this.property.pStandingScreenIds
        .split(',')
        .filter((id) => {
          return +id !== standingScreen.id;
        })
        .join(',');
    } else {
      if (this.property.pStandingScreenIds) {
        const ids = this.property.pStandingScreenIds.split(',');
        ids.push(standingScreen.id);
        this.property.pStandingScreenIds = ids.join(',');
      } else {
        this.property.pStandingScreenIds = standingScreen.id + '';
      }

      if (!this.isSelected) {
        // from 0 to n
        this.isSelected = true;
      }
    }

    this.selection.toggle(standingScreen);
    this.standingScreenSelection = this.selection;
  }

  dualScreenSelectDeselect(dualScreen: any) {

    if (this.dualScreenSelection.isSelected(dualScreen)) {
      this.property.pDualScreenIds = this.property.pDualScreenIds
        .split(',')
        .filter((id) => {
          return +id !== dualScreen.id;
        })
        .join(',');
    } else {
      if (this.property.pDualScreenIds) {
        const ids = this.property.pDualScreenIds.split(',');
        ids.push(dualScreen.id);
        this.property.pDualScreenIds = ids.join(',');
      } else {
        this.property.pDualScreenIds = dualScreen.id + '';
      }

      if (!this.isSelected) {
        // from 0 to n
        this.isSelected = true;
      }
    }

    this.selection.toggle(dualScreen);
    this.dualScreenSelection = this.selection;
  }

  posterSelectDeselect(poster: any) {
    if (this.posterSelection.isSelected(poster)) {
      this.property.pPosterIds = this.property.pPosterIds
        .split(',')
        .filter((id) => {
          return +id !== poster.id;
        })
        .join(',');
    } else {
      // this.data.building.pPosterIds.push(poster.id);

      if (this.property.pPosterIds) {
        const ids = this.property.pPosterIds.split(',');
        ids.push(poster.id);
        this.property.pPosterIds = ids.join(',');
      } else {
        this.property.pPosterIds = poster.id + '';
      }

      if (!this.isSelected) {
        // from 0 to n
        this.isSelected = true;
      }
    }

    this.selection.toggle(poster);
    this.posterSelection = this.selection;
  }

  magicPosterSelectDeselect(magicPoster: any) {
    if (this.magicSelection.isSelected(magicPoster)) {
      this.property.pMagicPosterIds = this.property.pMagicPosterIds
        .split(',')
        .filter((id) => {
          return +id !== magicPoster.id;
        })
        .join(',');
    } else {
      if (this.property.pMagicPosterIds) {
        const ids = this.property.pMagicPosterIds.split(',');
        ids.push(magicPoster.id);
        this.property.pMagicPosterIds = ids.join(',');
      } else {
        this.property.pMagicPosterIds = magicPoster.id + '';
      }

      if (!this.isSelected) {
        // from 0 to n
        this.isSelected = true;
      }
    }

    this.selection.toggle(magicPoster);
    this.magicSelection = this.selection;
  }

  singleSelectDeselect(event: any, point: any) {
    if (this.tabIndex === 0) {
      this.screenSelectDeselect(point);
    } else if (this.tabIndex === 1) {
      this.standingScreenSelectDeselect(point);
    } else if (this.tabIndex === 2) {
      this.dualScreenSelectDeselect(point);
    } else if (this.tabIndex === 3) {
      this.posterSelectDeselect(point);
    }
    this.updatePoints();
    this.setPopulation();
  }

  updatePoints(): void {

    if (this.property.pScreenIds) {
      this.property.pScreen =
        this.property.pScreenIds.trim() === ''
          ? 0
          : this.property.pScreenIds.split(',').length;
    } else {
      this.property.pScreen = 0;
    }

    if (this.property.pStandingScreenIds) {
      this.property.pStandingScreen =
        this.property.pStandingScreenIds.trim() === ''
          ? 0
          : this.property.pStandingScreenIds.split(',').length;
    } else {
      this.property.pStandingScreen = 0;
    }

    if (this.property.pDualScreenIds) {
      this.property.pDualScreen =
        this.property.pDualScreenIds.trim() === ''
          ? 0
          : this.property.pDualScreenIds.split(',').length;
    } else {
      this.property.pDualScreen = 0;
    }

    if (this.property.pPosterIds) {
      this.property.pPoster =
        this.property.pPosterIds.trim() === ''
          ? 0
          : this.property.pPosterIds.split(',').length;
    } else {
      this.property.pPoster = 0;
    }

    if (this.property.pMagicPosterIds) {
      this.property.pMagicPoster =
        this.property.pMagicPosterIds.trim() === ''
          ? 0
          : this.property.pMagicPosterIds.split(',').length;
    } else {
      this.property.pMagicPoster = 0;
    }

    if (
      !this.screenSelection.hasValue() &&
      !this.standingScreenSelection.hasValue() &&
      !this.posterSelection.hasValue() &&
      !this.magicSelection.hasValue() &&
      !this.dualScreenSelection.hasValue()
    ) {
      this.isSelected = false;
    }

    this.salesService.updatePoints(this.property);
  }

  setPopulation() {
    this.salesService.setPopulation();
  }

  isPointAvailable(pointId: any) {
    return this.salesUsedPointIds.indexOf(',' + pointId + ',') !== -1;
  }

  ngOnDestroy() {
    if (this.viewer) {
      this.viewer.destroy();
    }
  }
}
